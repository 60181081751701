/* font size system (rem)
0.694rem/0.833rem/1rem/1.2rem/1.44rem/1.728rem/2.074rem/2.488rem/2.986rem */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Readex Pro", sans-serif;
  background-color: #014e4e;
  color: #f6f5e3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#hero-section {
  background-image: url(../public/images/01.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#why-us {
  background-color: #032827;
}

nav a {
  color: #f8fdfd !important;
}

#hero-section h1 {
  color: #f8fdfd;
  font-size: 3.986rem;
  font-weight: 500;
}

.layer {
  background-color: rgba(00, 00, 00, 0.3);
}

#hero-section .layer {
  height: 100vh;
}
.hero-header {
  height: 80%;
}

.why-us-items {
  border: 2px solid #f6f5e3;
  padding: 40px;
  background-color: #f6f5e3;
  color: #014e4e;
  border-radius: 20px;
  height: 90%;
}
#about-us li,
#about-us p,
#our-services p {
  color: #ddddcc;
}

.why-us-items p {
  font-size: 0.833rem;
  color: #4d8383;
}

.hero-content a,
.price-btn {
  padding: 10px 20px;
  text-decoration: none;
  background-color: #f6f5e3;
  color: #014e4e;
  border: none;
  border-radius: 5px;

  display: inline-block;
}
.hero-content a :hover {
  background-color: #014e4e;
  color: #f6f5e3;
}

.about-us-image img {
  height: 30rem;
  border-radius: 10px;
}

.custom-color {
  background-color: #f6f5e3;
}

.custom-nav .nav-link {
  color: #014e4e;
}

@media (max-width: 992px) {
  /* Your custom styles for xs and sm screens go here */
  .second-row {
    flex-direction: column-reverse !important;
  }
  #hero-section h1 {
    color: #f6f5e3;
    font-size: 2.986rem;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  /* Your custom styles for xs and sm screens go here */

  .custom-color {
    background-color: #f6f5e3;
  }
  .custom-nav .nav-link {
    color: #014e4e !important;
  }
  #hero-section h1 {
    color: #f6f5e3;
    font-size: 2.986rem;
    font-weight: 500;
  }
  .form-bg {
    border-radius: 20px;
  }
  .bg-img {
    height: 12rem;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
  }
  .form-bg {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
  }
  .form-direction {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 576px) {
  /* Your custom styles for xs and sm screens go here */
  #hero-section h1 {
    color: #f6f5e3;
    font-size: 2.4rem;
    font-weight: 500;
    margin: 30px;
  }
  #footer-section {
    text-align: center;
  }
  .form-third-pairs {
    flex-direction: column;
  }
  .form-second-pairs {
    flex-direction: column;
  }
  .form-second-pairs {
    flex-direction: column;
  }
  .form-fourth-pairs {
    flex-direction: column;
  }
  .form-names {
    flex-direction: column;
  }
  .form-content .FormGroupClass {
    /* Your styles for Form.Control */
    width: 100% !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .phone-input-container {
    flex-direction: column;
  }
  .phone-code-box {
    width: 100% !important;
  }
  .phone-input {
    width: 100% !important;
  }
  .hero-btn {
    margin: 30px;
  }
  .our-services-img{
    height: 400px !important;
  
  }
}
.phone-input {
  width: 60%;
}

.phone-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px;
}
.phone-code-box {
  /* border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; */
  width: 40%;
}

#about-us li {
  list-style: none;
}

#about-us{
  background-color: #032827;

}

#subscription-form {
  background-color: #032827;
}

.form-content {
  width: 80%;
  margin: auto;
}
.bg-img {
  background-image: url(../public/images/02.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.form-layer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.form-bg {
  background-color: #014e4e;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.form-btn {
  height: 50px;
  width: 120px;
  background-color: #f6f5e3;
  color: #014e4e;
  border: none;
  border-radius: 5px;
  margin: 10px;
}
.form-btn:disabled {
  background-color: #f6f5e3;
  color: #014e4e;
}
.form-btn:hover {
  background-color: #f6f5e3;
  color: #014e4e;
}
.form-content input,
.form-content select,
.form-content option,
.form-content input:focus {
  background-color: #f6f5e3;
  color: #014e4e;
}

.form-content input:focus,
.form-content select:focus {
  border-color: #032827; /* Change this to your desired color */
  box-shadow: 0 0 10px #032827; /* Optional: Add a box shadow for emphasis */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#footer-section img {
  width: 250px;
  height: 100px;
}
#footer-section li {
  list-style: none;
  margin-left: 40px;
}

.whats-btn {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: grid;
  place-items: center;
  background: #f6f5e3;
  padding: 0.4em;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.5s;
}
.whats-btn:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateY(0.5em);
}

.Phone-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  width: 70%;
}
.code-select {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 30%;
}

.card-price a {
  text-decoration: none;
  color: #f6f5e3;
}
.error-bg {
  color: #ff3c38;
}

.price-card {
  height: 30rem;
  background-color: #032827;
  color: #f6f5e3;
  border: 2px solid #f6f5e3;
}

.price-btn {
  background-color: #014e4e;
  color: #f6f5e3;
}
.price-btn:hover {
  background-color: #014e4e;
  color: #f6f5e3;
}

.line {
  background-color: #f6f5e3;
  height: 2px;
}
.social-ul {
  height: 100%;
}
.hero-btn {
  background-color: #f8fdfd !important;
}
#about-us p , #our-services p {
  color: #ddddcc;
  line-height: 40px;
}
#why-us p {
  line-height: 25px;
}

#our-services {
  background-color: #014e4e;
}

.our-services-img {
  height: 600px;
  width: 100%;
}
